import React, { Fragment }  from 'react';
import { graphql } from 'gatsby';

import CallToAction from '../components/CallToAction';
import Hero from '../components/Hero';
import MetaData from '../components/MetaData';
import ServicesContent from '../components/ServicesContent';
import ServiceCards from '../components/ServiceCards';

const Services = ({ data }) => {

	let {
		wordpressPage: {
			title,
			acf: {
				seo_title,
				seo_description,
				seo_images,
				hero,
				services,
				call_to_action_title,
				call_to_action_text,
				call_to_action_link,
				call_to_action_link_text,
			}
		},
		allWordpressWpBlServices: {
			edges: allServices
		}
	} = data;

	services = services.map(({ wordpress_id }) => {
		let service = allServices.find(({ node }) => node.wordpress_id === wordpress_id);

		return service ? service.node : null;
	});

	return(
		<Fragment>
			<MetaData
				title={seo_title || title}
				desciption={seo_description}
				images={seo_images}
			/>

			<Hero
				title={hero.hero_title}
				text={hero.hero_text}
				image={hero.hero_image}
				imageMobile={hero.hero_image_mobile}
			/>

			<ServiceCards
				services={services}
				dontMatchServices
				noBorders
				linked
			/>

			<ServicesContent
				services={services}
			/>

			<CallToAction
				title={call_to_action_title}
				text={call_to_action_text}
				link={call_to_action_link.source_url}
				linkText={call_to_action_link_text}
			/>
		</Fragment>
	);
}

export const pageQuery = graphql`
    query {
		wordpressPage(slug: { eq: "services"}) {
			title
			acf {
				seo_title
				seo_description
				seo_images {
					image {
						source_url
					}
				}
				hero {
					hero_title
					hero_text
					hero_image {
						sizes {
							large {
								source_url
								alt_text
							}
						}
					}
					hero_image_mobile {
						sizes {
							large {
								source_url
								alt_text
							}
						}
					}
				}
				services {
					wordpress_id
				}
				call_to_action_title
				call_to_action_text
				call_to_action_link {
					source_url
				}
				call_to_action_link_text
			}
		}
		allWordpressWpBlServices {
			edges {
				node {
					wordpress_id
					title
					slug
					content
					acf {
						seo_title
						seo_description
						seo_images {
							image {
								source_url
							}
						}
						service_image {
							sizes {
								large {
									source_url
									alt_text
								}
							}
						}
						testimonial_quote
						testimonial_author
						testimonial_image {
							source_url
							alt_text
						}
						services_services {
							title
							text
						}
					}
				}
			}
		}
	}
`;

export default Services;