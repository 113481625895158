import React from 'react';
import styled from 'styled-components';
import SVG from 'react-svg';

import Container from './Container';
import FadeEntry from './FadeEntry';
import Quote from './Quote';
import Title from './Title';

const ServicesContent = ({ services }) => {

	if(!services || !services.length) return null;

	return(
		<Outer>
			{ services.map(({ wordpress_id, slug, title, content, acf }) => {
				return(
					<Service
						key={`service-${wordpress_id}`}
						id={slug}
					>
						<Container>
							<div className="service__main">
								<FadeEntry
									className="service__main__content"
								>
									<Title
										as={'h3'}
									>{ title }</Title>

									<div dangerouslySetInnerHTML={{ __html: content }} />
								</FadeEntry>

								{ acf.service_image ? (
									<FadeEntry
										className="service__main__img"
									>
										<img
											src={acf.service_image.sizes.large.source_url}
											alt={acf.service_image.sizes.large.alt_text}
										/>
									</FadeEntry>
								) : null }
							</div>

							{ acf.services_services && !!acf.services_services.length && acf.services_services[0]['title'] ? (
								<div className="service__services">
									{ acf.services_services.map(({ title, text }) => (
										<FadeEntry
											className={'service__service'}
											key={`service__service-${title}`}
										>
											<SVG
												src={'/icons/checkmark-circle.svg'}
											/>
											<p>{ title }</p>
										</FadeEntry>
									)) }
								</div>
							) : null }
							
							<Quote
								quote={acf.testimonial_quote}
								author={acf.testimonial_author}
							/>
						</Container>
					</Service>
				);
			}) }
		</Outer>
	);
}

// const Quote = styled.blockquote`
// 	padding: 80px 20px;
// 	text-align: center;
// 	font-size: ${({ theme }) => theme.fontSize.bodyLg};
// 	background: ${({ theme }) => theme.color.blue};
// 	color: ${({ theme }) => theme.color.white};
	
// 	.quote {
// 		&__quote {
// 			max-width: 750px;
// 			margin: 0 auto;
// 		}
	
// 		&__author {
// 			margin-top: 22px;
			
// 			strong {
// 				line-height: 150%;
// 			}
// 		}
// 	}
// `;

const Service = styled.div`
	padding-top: 100px;
	&:nth-of-type(even) {
		.service__main {
			flex-direction: row-reverse;
		}
	}
`;

const Outer = styled.div`
	padding-bottom: 100px;
	
	.service {
		&__main {
			display: flex;
			justify-content: space-between;
			
			&__content {
				flex: 1;
				max-width: 650px;
				font-size: ${({ theme }) => theme.fontSize.bodyLg};
				
				${Title} {
					margin-bottom: 20px;
					font-size: ${({ theme }) => theme.fontSize.titleLg};
				}
			}
			
			&__img {
				width: 33%;
				padding-top: 60px;
				
				img {
					width: 66%;
					margin: 0 auto;
				}
			}
			
			+* {
				margin-top: 100px;
			}
		}
		
		&__services {
			display: flex;
			flex-wrap: wrap;
			justify-content: center;
			
			+* {
				margin-top: 90px;
			}
		}
		
		&__service {
			display: flex;
			align-items: flex-start;
			width: 25%;
			padding: 0 10px;
			
			svg {
				position: relative;
				top: 5px;
				width: 32px;
				height: 32px;
				margin-right: 14px;
			}
			
			p {
				font-size: ${({ theme }) => theme.fontSize.bodyLg};
				font-family: ${({ theme }) => theme.fontFamily.sansHeavy};
			}
			
			&:nth-of-type(4n+1) {
				//padding-left: 0;
				//padding-right: 20px;
			}
			&:nth-of-type(4n+4) {
				//padding-right: 0;
				//padding-left: 20px;
			}
			&:nth-of-type(n+5) {
				margin-top: 40px;
			}
		}
		
		@media (max-width: 991px) {
			&__service {
				width: calc(100% / 3);
				
				//&:nth-of-type(4n+1) {
				//	padding-left: 0;
				//	padding-right: 0;
				//}
				//&:nth-of-type(4n+4) {
				//	padding-right: 0;
				//	padding-left: 0;
				//}
				&:nth-of-type(n+5) {
					margin-top: 0;
				}
				
				//&:nth-of-type(3n+1) {
				//	padding-left: 0;
				//	padding-right: 20px;
				//}
				//&:nth-of-type(3n+3) {
				//	padding-right: 0;
				//	padding-left: 20px;
				//}
				&:nth-of-type(n+4) {
					margin-top: 40px;
				}
			}
		}
		
		@media (max-width: 767px) {
			&__main,
			&__services {
				+* {
					margin-top: 60px;
				}
			}
		
			&__service {
				width: 50%;
				
				//&:nth-of-type(3n+1) {
				//	padding-left: 0;
				//	padding-right: 0;
				//}
				//&:nth-of-type(3n+3) {
				//	padding-right: 0;
				//	padding-left: 0;
				//}
				&:nth-of-type(n+4) {
					margin-top: 0;
				}
				
				&:nth-of-type(2n+1) {
					padding-left: 0;
					padding-right: 20px;
				}
				&:nth-of-type(2n+2) {
					padding-right: 0;
					padding-left: 20px;
				}
				&:nth-of-type(n+3) {
					margin-top: 40px;
				}
			}
		}
		
		@media (max-width: 599px) {
			&__main {
				&__img {
					display: none;
				}
			}
		}
		
		@media (max-width: 479px) {
			&__service {
				width: 100%;
				
				&:nth-of-type(2n+1) {
					padding-left: 0;
					padding-right: 0;
				}
				&:nth-of-type(2n+2) {
					padding-right: 0;
					padding-left: 0;
				}
				&:nth-of-type(n+3) {
					margin-top: 0;
				}
				
				&:nth-of-type(n+2) {
					margin-top: 40px;
				}
			}
		}
	}
`;

export default ServicesContent;